import { useControls, folder } from 'leva';

export function useWallsMaterial() {
  return useControls(
    'Walls',
    () => ({
      Material: folder({
        roughness: { value: 1, min: 0, max: 1 },
        metalness: { value: 0, min: 0, max: 1 },
        color: '#c1c1c1',
      }),
    }),
    {
      collapsed: true,
    },
  );
}
