import { Billboard, Html, Sphere, Text, useCursor } from '@react-three/drei';
import { Vector3 } from '@react-three/fiber';
import { EventHandlers } from '@react-three/fiber/dist/declarations/src/core/events';
import { useState } from 'react';

export type AnnotationProps = {
  text: string;
  description: string;
  position: Vector3;
  name: string;
  image?: string;
  snapshot: {
    target: [number, number, number];
    position: [number, number, number];
  };
};

interface AnnotationComponentProps
  extends Pick<AnnotationProps, 'name' | 'text' | 'position'>,
    EventHandlers {}

const Annotation: React.FC<AnnotationComponentProps> = ({
  name,
  text,
  position,
  onClick,
  onPointerMissed,
  onContextMenu,
}) => {
  const [hovered, setHovered] = useState(false);

  useCursor(hovered);

  return (
    <Billboard
      follow={true}
      lockX={false}
      lockY={false}
      lockZ={false}
      position={position}
      name={name}
    >
      <Sphere
        args={[0.2]}
        onPointerOver={e => (e.stopPropagation(), setHovered(true))}
        onPointerOut={e => setHovered(false)}
        onClick={onClick}
        onPointerMissed={onPointerMissed}
        onContextMenu={onContextMenu}
      >
        <meshStandardMaterial color="red" />
      </Sphere>
      <Html
        zIndexRange={[100, 0]}
        center
        color="white"
        style={{
          pointerEvents: 'none',
          fontWeight: 'bold',
          textShadow:
            '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
        }}
        position={[0, 0, 0]}
      >
        {text}
      </Html>
    </Billboard>
  );
};

export default Annotation;
