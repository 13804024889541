import { useState } from 'react';

export function useModal(handleClose?: () => void) {
  const [modalOpenState, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    handleClose && handleClose();
  };

  const openModal = () => setOpen(true);

  return {
    modalOpenState,
    openModal,
    closeModal,
  };
}
