import { useControls, folder } from 'leva';

export function useEdgesControls() {
  return useControls(
    'Debug Edges',
    () => ({
      enabled: false,
      Material: folder(
        {
          color: {
            value: '#662266',
          },
          transparent: true,
          depthTest: false,
        },
        {
          collapsed: true,
        },
      ),
    }),
    {
      collapsed: true,
    },
  );
}
