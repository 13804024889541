import { useControls, folder } from 'leva';

export function usePlumbingControls() {
  return useControls({
    'Debug Plumbing': folder(
      {
        Material: folder({
          transparent: true,
          depthTest: false,
          Hot: folder({
            color_cold: {
              value: '#0006ff',
            },
          }),
          Cold: folder({
            color_hot: {
              value: '#ff0000',
            },
          }),
        }),
      },
      {
        collapsed: true,
      },
    ),
  });
}
