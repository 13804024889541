import { folder, useControls } from 'leva';

export function useUIControls() {
  return useControls({
    Wireframe: false,
    Layers: folder(
      {
        Plumbing: false,
        Ventilation: false,
      },
      {
        collapsed: false,
      },
    ),
  });
}
