import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { blue, grey } from '@mui/material/colors';

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    margin: 5px 0;
    width: 100%;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${grey[900]};
    border: 1px solid ${grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === 'dark' ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === 'dark' ? blue[600] : blue[200]
      };
    }
  `,
);

interface FormDialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
  image?: string;
}

const Modal: React.FC<FormDialogProps> = ({
  open,
  handleClose,
  title,
  description,
  image,
}) => (
  <Dialog open={open} onClose={handleClose} fullWidth>
    <DialogTitle>Case #{title}</DialogTitle>
    {image && <img src={image} />}
    <DialogContent>
      <Textarea defaultValue={description} />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default Modal;
