import { useControls, folder, button } from 'leva';
import { AnnotationProps } from '../../components/annotation';
import { ButtonInput } from 'leva/dist/declarations/src/types';
import { CameraControls } from '@react-three/drei';
import { useCallback } from 'react';

type AnnotationZoomControls = (
  camera: CameraControls,
  annotation: AnnotationProps['snapshot'],
) => Promise<void>;

interface AnnotationControlsProps {
  annotations: AnnotationProps[];
  handleSetCurrentAnnotation: (annotation: AnnotationProps | null) => void;
  camera?: CameraControls;
}

export function useAnnotations({
  annotations,
  handleSetCurrentAnnotation,
  camera,
}: AnnotationControlsProps) {
  const annotationsEls: Record<string, ButtonInput> = {};

  annotations.forEach(annotation => {
    annotationsEls['Note ' + annotation.text] = button(() =>
      camera
        ? handleSetCurrentAnnotation(annotation)
        : console.log('no camera'),
    );
  });

  const handleAnnotationZoom = useCallback<AnnotationZoomControls>(
    (camera, annotation) =>
      camera.setLookAt(...annotation.position, ...annotation.target, true),
    [],
  );

  return {
    controls: useControls(
      {
        Notes: folder(annotationsEls),
      },
      [camera],
    ),
    handleAnnotationZoom,
  };
}
