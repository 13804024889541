/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 test.glb --shadows --types --transform --keepmeshes 
Files: test.glb [112.9MB] > test-transformed.glb [6.53MB] (94%)
*/

import * as THREE from 'three';
import React from 'react';
import { MeshStandardMaterialProps } from '@react-three/fiber';
import { EdgesProps } from 'types/edges';
import { PlumbingProps } from 'types/plumbing';
import { MeshTransmissionMaterialProps } from 'types/mesh-transmission-material';
import { useGLTF, Edges, MeshTransmissionMaterial } from '@react-three/drei';
import { GLTF } from 'three-stdlib';

type GLTFResult = GLTF & {
  nodes: {
    Wall10: THREE.Mesh;
    Wall11: THREE.Mesh;
    Wall12: THREE.Mesh;
    Wall09: THREE.Mesh;
    Wall06: THREE.Mesh;
    Wall07: THREE.Mesh;
    Wall08: THREE.Mesh;
    Wall13: THREE.Mesh;
    Wall16: THREE.Mesh;
    WhiteFiller04: THREE.Mesh;
    WhiteFiller03: THREE.Mesh;
    Baseboard: THREE.Mesh;
    Baseboard001: THREE.Mesh;
    Baseboard002: THREE.Mesh;
    Obj_Baseboard: THREE.Mesh;
    WhiteFiller05: THREE.Mesh;
    WhiteFiller02: THREE.Mesh;
    SocketTV_Body02: THREE.Mesh;
    SocketTV_Body02_1: THREE.Mesh;
    SocketTV_AntennaSocket04: THREE.Mesh;
    SocketTV_AntennaSocket04_1: THREE.Mesh;
    SocketTV_AntennaSocket05: THREE.Mesh;
    SocketTV_AntennaSocket05_1: THREE.Mesh;
    SocketTV_AntennaSocket06: THREE.Mesh;
    SocketTV_AntennaSocket06_1: THREE.Mesh;
    Socket_GB: THREE.Mesh;
    SocketSwitch_Body04: THREE.Mesh;
    SocketSwitch_Body04_1: THREE.Mesh;
    SocketSwitch_Body04_2: THREE.Mesh;
    SocketSwitch_Body04_3: THREE.Mesh;
    SocketQuadroSwitch_Body: THREE.Mesh;
    Baseboard003: THREE.Mesh;
    Baseboard004: THREE.Mesh;
    Baseboard005: THREE.Mesh;
    Baseboard006: THREE.Mesh;
    Baseboard007: THREE.Mesh;
    BaseboardWindowBeadroom: THREE.Mesh;
    Window_Frame07: THREE.Mesh;
    Window_Glass01: THREE.Mesh;
    Window_BarLower01: THREE.Mesh;
    Window_BarUpper01: THREE.Mesh;
    Window_Frame09: THREE.Mesh;
    Window_Frame11: THREE.Mesh;
    Window_Frame04: THREE.Mesh;
    Window_Frame10: THREE.Mesh;
    Window_Frame06: THREE.Mesh;
    Window_BarBehind01: THREE.Mesh;
    Window_Glass02: THREE.Mesh;
    Window_Frame05: THREE.Mesh;
    Window_Frame05_1: THREE.Mesh;
    Window_Frame01: THREE.Mesh;
    Window_Frame03: THREE.Mesh;
    Window_RubberSeal01: THREE.Mesh;
    Window_Ventilation06: THREE.Mesh;
    Window_Ventilation07: THREE.Mesh;
    Window_Ventilation05: THREE.Mesh;
    Window_Ventilation02: THREE.Mesh;
    Window_Ventilation01: THREE.Mesh;
    Window_Ventilation03: THREE.Mesh;
    Window_Ventilation04: THREE.Mesh;
    Window_Handle04: THREE.Mesh;
    Window_Handle04_1: THREE.Mesh;
    Window_Handle04_2: THREE.Mesh;
    Window_Handle04_3: THREE.Mesh;
    Window_Handle03: THREE.Mesh;
    Window_Handle03_1: THREE.Mesh;
    Window_Handle03_2: THREE.Mesh;
    Window_Handle03_3: THREE.Mesh;
    Window_Handle01: THREE.Mesh;
    Window_Handle01_1: THREE.Mesh;
    Window_Handle01_2: THREE.Mesh;
    Window_Handle01_3: THREE.Mesh;
    Window_Handle02: THREE.Mesh;
    Window_Handle02_1: THREE.Mesh;
    Window_Handle02_2: THREE.Mesh;
    Window_Handle02_3: THREE.Mesh;
    Window_Handle05: THREE.Mesh;
    Window_Handle05_1: THREE.Mesh;
    Window_Handle05_2: THREE.Mesh;
    Window_Handle05_3: THREE.Mesh;
    Window_Handle06: THREE.Mesh;
    Window_Handle06_1: THREE.Mesh;
    Window_Handle06_2: THREE.Mesh;
    Window_Handle06_3: THREE.Mesh;
    Window_Hook01: THREE.Mesh;
    Window_Hook01_1: THREE.Mesh;
    Window_Hook01_2: THREE.Mesh;
    Window_Hook01_3: THREE.Mesh;
    Window_Filler02: THREE.Mesh;
    Window_Filler03: THREE.Mesh;
    Window_Filler01: THREE.Mesh;
    Wall14: THREE.Mesh;
    Baseboard008: THREE.Mesh;
    Baseboard009: THREE.Mesh;
    Baseboard010: THREE.Mesh;
    Baseboard011: THREE.Mesh;
    Baseboard012: THREE.Mesh;
    Door_Frame08: THREE.Mesh;
    Door_Frame09: THREE.Mesh;
    Door_Frame07: THREE.Mesh;
    Door_KeyLockPlate02: THREE.Mesh;
    Door_KeyLockPlate01: THREE.Mesh;
    Door_Shaft05: THREE.Mesh;
    Door_Shaft05_1: THREE.Mesh;
    Door03: THREE.Mesh;
    Door_Latch03: THREE.Mesh;
    Door_Latch02: THREE.Mesh;
    Door_Latch01: THREE.Mesh;
    Door_Lock03: THREE.Mesh;
    Door_Lock02: THREE.Mesh;
    Door_HingeLower02: THREE.Mesh;
    Door_HingeLower02_1: THREE.Mesh;
    Door_HingeLower01: THREE.Mesh;
    Door_HingeLower01_1: THREE.Mesh;
    Door_HingeLowerFrame02: THREE.Mesh;
    Door_HingeLowerFrame02_1: THREE.Mesh;
    Door_HingeLowerFrame01: THREE.Mesh;
    Door_HingeLowerFrame01_1: THREE.Mesh;
    Door_Hinge06: THREE.Mesh;
    Door_Hinge06_1: THREE.Mesh;
    Door_Hinge04: THREE.Mesh;
    Door_Hinge04_1: THREE.Mesh;
    Door04: THREE.Mesh;
    Door_Hinge09: THREE.Mesh;
    Door_Hinge09_1: THREE.Mesh;
    Door_Latch04: THREE.Mesh;
    Door_KeyLockPlate04: THREE.Mesh;
    Door_KeyLockPlate03: THREE.Mesh;
    Door_Keyhole04: THREE.Mesh;
    Door_Shaft06: THREE.Mesh;
    Door_Frame04: THREE.Mesh;
    Door_Frame06: THREE.Mesh;
    Door_Frame05: THREE.Mesh;
    Door_Frame01: THREE.Mesh;
    Door02: THREE.Mesh;
    Door_Shaft03: THREE.Mesh;
    Door_Shaft03_1: THREE.Mesh;
    Door_Shaft01: THREE.Mesh;
    Door_Bolt20: THREE.Mesh;
    Door_LockHandle03: THREE.Mesh;
    Door_LockHandle01: THREE.Mesh;
    Door_LockHandle02: THREE.Mesh;
    Wall15: THREE.Mesh;
    Radiator_Fins03: THREE.Mesh;
    Radiator_Fins03_1: THREE.Mesh;
    Radiator_Fins03_2: THREE.Mesh;
    Radiator_BodyCapTop03: THREE.Mesh;
    Radiator_BodyCapTop02: THREE.Mesh;
    Radiator_BodyCapTop03_1: THREE.Mesh;
    Radiator_Valve03: THREE.Mesh;
    Radiator_Valve03_1: THREE.Mesh;
    Radiator_Valve03_2: THREE.Mesh;
    Radiator_Valve03_3: THREE.Mesh;
    Radiator_PipePlasticCap03: THREE.Mesh;
    Radiator_PipePlasticCap03_1: THREE.Mesh;
    Radiator_PipePlasticCap03_2: THREE.Mesh;
    Radiator_PipePlasticCap03_3: THREE.Mesh;
    Radiator_Fins05: THREE.Mesh;
    Radiator_BodyCapSide08: THREE.Mesh;
    Radiator_BodyCapSide08_1: THREE.Mesh;
    Radiator_BodyCapTop04: THREE.Mesh;
    Radiator_BodyCap07: THREE.Mesh;
    Radiator_BodyCap07_1: THREE.Mesh;
    Radiator_BodyCap07_2: THREE.Mesh;
    Radiator_Fins04: THREE.Mesh;
    Radiator_BodyCapBottom07: THREE.Mesh;
    Radiator_BodyCapBottom07_1: THREE.Mesh;
    Radiator_BodyCapBottom07_2: THREE.Mesh;
    Radiator_Foot03: THREE.Mesh;
    Radiator_Foot03_1: THREE.Mesh;
    Radiator_Foot02: THREE.Mesh;
    Radiator_Foot02_1: THREE.Mesh;
    Radiator_Foot01: THREE.Mesh;
    Radiator_Foot01_1: THREE.Mesh;
    Radiator_Pipes05: THREE.Mesh;
    Radiator_Pipes05_1: THREE.Mesh;
    Radiator_Pipes05_2: THREE.Mesh;
    Radiator_Pipes05_3: THREE.Mesh;
    Radiator_Pipes06: THREE.Mesh;
    Radiator_Pipes06_1: THREE.Mesh;
    WiresShaft01: THREE.Mesh;
    WiresShaft02: THREE.Mesh;
    MaintenanceDoor: THREE.Mesh;
    Exhaust_Duct: THREE.Mesh;
    Exhaust_Hood: THREE.Mesh;
    Wall02: THREE.Mesh;
    Wall01: THREE.Mesh;
    Wall03: THREE.Mesh;
    Wall05: THREE.Mesh;
    GlassSeparator_Glass: THREE.Mesh;
    Drain_Frame: THREE.Mesh;
    Drain: THREE.Mesh;
    Closet_Frame04: THREE.Mesh;
    Closet_Frame03: THREE.Mesh;
    Closet_Frame03_1: THREE.Mesh;
    Closet_Frame02: THREE.Mesh;
    Closet_Filler: THREE.Mesh;
    Closet_Door04: THREE.Mesh;
    Closet_Door04_1: THREE.Mesh;
    Closet_Hinge02: THREE.Mesh;
    Closet_Hinge02_1: THREE.Mesh;
    Flush_Panel: THREE.Mesh;
    Flush_Logo02: THREE.Mesh;
    Flush_ButtonPanel: THREE.Mesh;
    Flush_Logo01: THREE.Mesh;
    Toilet: THREE.Mesh;
    Seat: THREE.Mesh;
    Ventilation_Outer: THREE.Mesh;
    Ventilation_Inner: THREE.Mesh;
    Basin_Sink: THREE.Mesh;
    Basin_Waste01: THREE.Mesh;
    Basin_Waste02: THREE.Mesh;
    Basin_Cup: THREE.Mesh;
    Basin_LockRing01: THREE.Mesh;
    Basin_LockRing01_1: THREE.Mesh;
    Basin_Rose: THREE.Mesh;
    Basin_PlasticTube: THREE.Mesh;
    Basin_Tube01: THREE.Mesh;
    Basin_TubeFitting: THREE.Mesh;
    Basin_Tube02: THREE.Mesh;
    Faucet_Bottom01: THREE.Mesh;
    Faucet_Handles01: THREE.Mesh;
    Faucet_Spout01: THREE.Mesh;
    Faucet_Aerator01: THREE.Mesh;
    Basin_Filler: THREE.Mesh;
    Basin_Overflow: THREE.Mesh;
    Basin_Strainer: THREE.Mesh;
    Hatch: THREE.Mesh;
    Door_Frame03: THREE.Mesh;
    Door_Frame02: THREE.Mesh;
    Door01: THREE.Mesh;
    Door_Hinge01: THREE.Mesh;
    Door_Hinge01_1: THREE.Mesh;
    Door_Hinge01_2: THREE.Mesh;
    Wall04: THREE.Mesh;
    Pillar01: THREE.Mesh;
    FloorSupportLights: THREE.Mesh;
    FloorSupport01: THREE.Mesh;
    Baseboard01: THREE.Mesh;
    Baseboard02: THREE.Mesh;
    Baseboard03: THREE.Mesh;
    DoorCloser_Bolt: THREE.Mesh;
    DoorCloser_Slide: THREE.Mesh;
    DoorCloser_Rail: THREE.Mesh;
    DoorCloser_Body: THREE.Mesh;
    Intercom_Phone: THREE.Mesh;
    Intercom_Body: THREE.Mesh;
    Intercom_Button01: THREE.Mesh;
    Intercom_Button01_1: THREE.Mesh;
    Intercom_Cord: THREE.Mesh;
    Stairs_Step01: THREE.Mesh;
    Stairs_Frame01: THREE.Mesh;
    Stairs_Frame01_1: THREE.Mesh;
    Stairs_RailingPlate03: THREE.Mesh;
    Stairs_RailingPlate03_1: THREE.Mesh;
    Stairs_2ndFloorSupport: THREE.Mesh;
    Stairs_SupportArm07: THREE.Mesh;
    Stairs_SupportArm07_1: THREE.Mesh;
    Railing_Frame03: THREE.Mesh;
    Railing_Frame02: THREE.Mesh;
    Railing_Frame01: THREE.Mesh;
    Railing_Rod01: THREE.Mesh;
    Railing_Rod01_1: THREE.Mesh;
    Railing_Rod01_2: THREE.Mesh;
    Railing_Frame04: THREE.Mesh;
    Baseboard04: THREE.Mesh;
    Floor02: THREE.Mesh;
    Window_Frame30: THREE.Mesh;
    Window_Frame12: THREE.Mesh;
    Window_Frame30_1: THREE.Mesh;
    Window_Frame29: THREE.Mesh;
    Window_Frame29_1: THREE.Mesh;
    Window_Frame26: THREE.Mesh;
    Window_Frame26_1: THREE.Mesh;
    Window_RubberSeal03: THREE.Mesh;
    Window_RubberSeal03_1: THREE.Mesh;
    Window_Frame24: THREE.Mesh;
    Window_Frame24_1: THREE.Mesh;
    Window_GlassUpper03: THREE.Mesh;
    Window_GlassUpper03_1: THREE.Mesh;
    Window_Frame25: THREE.Mesh;
    Window_Frame25_1: THREE.Mesh;
    Window_GlassLower02: THREE.Mesh;
    Window_GlassLower02_1: THREE.Mesh;
    Window_Frame22: THREE.Mesh;
    Window_Frame22_1: THREE.Mesh;
    Window_Frame23: THREE.Mesh;
    Window_Frame23_1: THREE.Mesh;
    Window_Ventilation18: THREE.Mesh;
    Window_Ventilation18_1: THREE.Mesh;
    Window_Ventilation18_2: THREE.Mesh;
    Window_Ventilation16: THREE.Mesh;
    Window_Ventilation16_1: THREE.Mesh;
    Window_Ventilation16_2: THREE.Mesh;
    Window_Ventilation17: THREE.Mesh;
    Window_Ventilation17_1: THREE.Mesh;
    Window_Ventilation17_2: THREE.Mesh;
    Window_Ventilation22: THREE.Mesh;
    Window_Ventilation22_1: THREE.Mesh;
    Window_Ventilation22_2: THREE.Mesh;
    Window_Ventilation23: THREE.Mesh;
    Window_Ventilation23_1: THREE.Mesh;
    Window_Ventilation23_2: THREE.Mesh;
    Window_Ventilation21: THREE.Mesh;
    Window_Ventilation21_1: THREE.Mesh;
    Window_Ventilation21_2: THREE.Mesh;
    Window_Ventilation20: THREE.Mesh;
    Window_Ventilation20_1: THREE.Mesh;
    Window_Ventilation20_2: THREE.Mesh;
    Window_Frame14: THREE.Mesh;
    Window_Frame14_1: THREE.Mesh;
    Window_Glass03: THREE.Mesh;
    Window_Glass03_1: THREE.Mesh;
    Window_Frame15: THREE.Mesh;
    Window_Glass04: THREE.Mesh;
    Window_Frame13: THREE.Mesh;
    Window_Frame19: THREE.Mesh;
    Window_RubberSeal02: THREE.Mesh;
    Window_Frame20: THREE.Mesh;
    Window_GlassUpper02: THREE.Mesh;
    Window_Frame18: THREE.Mesh;
    Window_GlassLower03: THREE.Mesh;
    Window_Frame16: THREE.Mesh;
    Window_Frame17: THREE.Mesh;
    Window_BarSide01: THREE.Mesh;
    Window_Baseboard01: THREE.Mesh;
    ['Window_FireproofCeiling01-01']: THREE.Mesh;
    ['Window_FireproofCeiling01-02']: THREE.Mesh;
    ['Window_BarLower02-01']: THREE.Mesh;
    ['Window_BarLower02-02']: THREE.Mesh;
    ['Window_BarLower02-03']: THREE.Mesh;
    ['Window_BarLower02-04']: THREE.Mesh;
    ['Window_BarLower02-05']: THREE.Mesh;
    ['Window_BarLower02-06']: THREE.Mesh;
    SocketWall_Body01: THREE.Mesh;
    SocketWall_Body01_1: THREE.Mesh;
    SocketWall_Body01_2: THREE.Mesh;
    Sink_Strainer: THREE.Mesh;
    Sink_Cap: THREE.Mesh;
    Sink_StrainerBasket: THREE.Mesh;
    Sink_Holder: THREE.Mesh;
    Sink: THREE.Mesh;
    Radiator_ValveIndicator02: THREE.Mesh;
    Light_LampOuterShell01: THREE.Mesh;
    Light_LampOuterShell01_1: THREE.Mesh;
    Light_LampOuterShell01_2: THREE.Mesh;
    Light_LampOuterShell01_3: THREE.Mesh;
    Light_LampReflector01: THREE.Mesh;
    Light_LampReflector01_1: THREE.Mesh;
    Light_LampReflector01_2: THREE.Mesh;
    Light_LampReflector01_3: THREE.Mesh;
    Light_Bulb01: THREE.Mesh;
    Light_Bulb01_1: THREE.Mesh;
    Light_Bulb01_2: THREE.Mesh;
    Light_Bulb01_3: THREE.Mesh;
    Light_Glass01: THREE.Mesh;
    Light_Glass01_1: THREE.Mesh;
    Light_Glass01_2: THREE.Mesh;
    Light_Glass01_3: THREE.Mesh;
    Light_InnerRing01: THREE.Mesh;
    Light_InnerRing01_1: THREE.Mesh;
    Light_InnerRing01_2: THREE.Mesh;
    Light_InnerRing01_3: THREE.Mesh;
    Light_Bulb02: THREE.Mesh;
    Light_Bulb02_1: THREE.Mesh;
    Light_Bulb02_2: THREE.Mesh;
    Light_Bulb02_3: THREE.Mesh;
    Light_Bulb03: THREE.Mesh;
    Light_Bulb03_1: THREE.Mesh;
    Light_Bulb03_2: THREE.Mesh;
    Light_Bulb03_3: THREE.Mesh;
    Light_CoronaLight010: THREE.Mesh;
    Light_CoronaLight010_1: THREE.Mesh;
    Light_CoronaLight010_2: THREE.Mesh;
    Light_CoronaLight010_3: THREE.Mesh;
    SocketDoubleSwitch_Socket01: THREE.Mesh;
    Balcony_Concrete: THREE.Mesh;
    Balcony_RailingBottomHolder01: THREE.Mesh;
    Balcony_RailingBottomHolder01_1: THREE.Mesh;
    Balcony_RailingBottomHolder01_2: THREE.Mesh;
    Balcony_RailingMain: THREE.Mesh;
    BaseFloor: THREE.Mesh;
    Pillar02: THREE.Mesh;
    Kitchen_Faucet001: THREE.Mesh;
    Kitchen_Faucet002: THREE.Mesh;
    Kitchen_Faucet003: THREE.Mesh;
    Kitchen_Faucet004: THREE.Mesh;
    Kitchen_Faucet005: THREE.Mesh;
    Kitchen_Faucet006: THREE.Mesh;
    Kitchen_Faucet007: THREE.Mesh;
    Kitchen_Faucet008: THREE.Mesh;
    Kitchen_Faucet009: THREE.Mesh;
    Kitchen_Faucet010: THREE.Mesh;
    Kitchen_Faucet011: THREE.Mesh;
    Kitchen_Faucet012: THREE.Mesh;
    Kitchen_Faucet013: THREE.Mesh;
    Kitchen_Faucet014: THREE.Mesh;
    Kitchen_Faucet015: THREE.Mesh;
    Kitchen_Faucet016: THREE.Mesh;
    Kitchen_Faucet017: THREE.Mesh;
    Ventilation1: THREE.Mesh;
    Ventilation3: THREE.Mesh;
    Ventilation2: THREE.Mesh;
    Ventilation4: THREE.Mesh;
    PlumbingHot1: THREE.Mesh;
    PlumbingCold1: THREE.Mesh;
    PlumbingCloak: THREE.Mesh;
    Obj_PlumbingCloak: THREE.Mesh;
    PlumbingHot2: THREE.Mesh;
    PlumbingCold2: THREE.Mesh;
    PlumbingRadiatorMinus1: THREE.Mesh;
    PlumbingRadiatorPlus1: THREE.Mesh;
    Socket_Body10: THREE.InstancedMesh;
    Socket_Cap10: THREE.InstancedMesh;
    Socket_Screw10: THREE.InstancedMesh;
    SocketLight05: THREE.InstancedMesh;
    Window_FrameCap10: THREE.InstancedMesh;
    Door_Bolt49: THREE.InstancedMesh;
    Door_Bolt29: THREE.InstancedMesh;
    Door_Shaft04: THREE.InstancedMesh;
    Door_Handle03: THREE.InstancedMesh;
    Door_PlasticCap14: THREE.InstancedMesh;
    Radiator_BodyCapSide06: THREE.InstancedMesh;
    Radiator_Pipes30: THREE.InstancedMesh;
    Radiator_PipeFitting11: THREE.InstancedMesh;
    Radiator_Hooks08: THREE.InstancedMesh;
    Radiator_BodyCapBottom05: THREE.InstancedMesh;
    Radiator_BodyCap05: THREE.InstancedMesh;
    Radiator_PipeFitting14: THREE.InstancedMesh;
    Radiator_Pipes28: THREE.InstancedMesh;
    Stairs_RailingPlate01: THREE.InstancedMesh;
    Stairs_Nut05: THREE.InstancedMesh;
    Stairs_Fastener18: THREE.InstancedMesh;
    Stairs_Cap05: THREE.InstancedMesh;
    mesh_195: THREE.InstancedMesh;
    Stairs_Rod01: THREE.InstancedMesh;
    Stairs_SupportArm06: THREE.InstancedMesh;
    Stairs_Step12: THREE.InstancedMesh;
    Balcony_RailingBar01: THREE.InstancedMesh;
    Balcony_RailingBolt01: THREE.InstancedMesh;
  };
  materials: {
    PaletteMaterial001: THREE.MeshStandardMaterial;
    PaletteMaterial002: THREE.MeshStandardMaterial;
    PaletteMaterial003: THREE.MeshPhysicalMaterial;
  };
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>
>;

export function Model({
  props,
  opts,
}: {
  props?: JSX.IntrinsicElements['group'];
  opts?: {
    edges?: EdgesProps;
    plumbing: PlumbingProps;
    ventilation?: EdgesProps;
    materials?: {
      walls?: MeshStandardMaterialProps;
      windowGlass?: MeshTransmissionMaterialProps;
      plumbing?: MeshStandardMaterialProps;
      ventilation?: MeshStandardMaterialProps;
    };
  };
}) {
  const { nodes, materials } = useGLTF(
    '/assets/test-transformed.glb',
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall10.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.814, 0, -5.209]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall11.geometry}
        material={materials.PaletteMaterial001}
        position={[1.71, 0, -1.648]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall12.geometry}
        material={materials.PaletteMaterial001}
        position={[3.186, 0, 1.524]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall09.geometry}
        material={materials.PaletteMaterial001}
        position={[4.085, 0, 5.352]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall06.geometry}
        material={materials.PaletteMaterial001}
        position={[8.039, 0, 0.168]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall07.geometry}
        material={materials.PaletteMaterial001}
        position={[3.198, 0, 2.176]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall08.geometry}
        material={materials.PaletteMaterial001}
        position={[4.951, 0.011, 1.943]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall13.geometry}
        material={materials.PaletteMaterial001}
        position={[4.361, 0, 4.169]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall16.geometry}
        material={materials.PaletteMaterial001}
        position={[4.009, 0, 2.999]}
        rotation={[-Math.PI / 2, 0, -0.01]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WhiteFiller04.geometry}
        material={materials.PaletteMaterial002}
        position={[-2.792, 0, -7.623]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WhiteFiller03.geometry}
        material={materials.PaletteMaterial002}
        position={[7.816, 0, -7.623]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.596, 0, -7.384]}
        rotation={[-Math.PI / 2, 0, 0.541]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard001.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.74, 0, -4.464]}
        rotation={[-Math.PI / 2, 0, 1.557]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard002.geometry}
        material={materials.PaletteMaterial001}
        position={[7.703, 0, -4.855]}
        rotation={[-Math.PI / 2, 0, -3.037]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Obj_Baseboard.geometry}
        material={materials.PaletteMaterial001}
        position={[3.708, 0, -6.009]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WhiteFiller05.geometry}
        material={materials.PaletteMaterial002}
        position={[-2.756, 0, -7.623]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WhiteFiller02.geometry}
        material={materials.PaletteMaterial002}
        position={[7.888, 0, -7.651]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketTV_Body02.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.805, 0, -4.418]}
        rotation={[-Math.PI / 2, 0, 0.002]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketTV_Body02_1.geometry}
        material={materials.PaletteMaterial001}
        position={[7.869, 0, -4.312]}
        rotation={[-Math.PI / 2, 0, 1.572]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketTV_AntennaSocket04.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.79, 0.096, -4.431]}
        rotation={[Math.PI, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketTV_AntennaSocket04_1.geometry}
        material={materials.PaletteMaterial001}
        position={[7.855, 0.096, -4.327]}
        rotation={[Math.PI, -Math.PI / 2, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketTV_AntennaSocket05.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.821, 0.096, -4.431]}
        rotation={[Math.PI, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketTV_AntennaSocket05_1.geometry}
        material={materials.PaletteMaterial001}
        position={[7.855, 0.096, -4.296]}
        rotation={[Math.PI, -Math.PI / 2, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketTV_AntennaSocket06.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.806, 0.078, -4.431]}
        rotation={[Math.PI, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketTV_AntennaSocket06_1.geometry}
        material={materials.PaletteMaterial001}
        position={[7.855, 0.078, -4.312]}
        rotation={[Math.PI, -Math.PI / 2, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Socket_GB.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.74, 0, -4.418]}
        rotation={[-Math.PI / 2, 0, 0.002]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketSwitch_Body04.geometry}
        material={materials.PaletteMaterial001}
        position={[2.624, 0.925, -4.39]}
        rotation={[-Math.PI / 2, 0, 0.002]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketSwitch_Body04_1.geometry}
        material={materials.PaletteMaterial001}
        position={[7.899, 0.925, 1.843]}
        rotation={[-Math.PI / 2, 0, 1.572]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketSwitch_Body04_2.geometry}
        material={materials.PaletteMaterial001}
        position={[2.644, 0.925, -4.3]}
        rotation={[-Math.PI / 2, 0, -2.174]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketSwitch_Body04_3.geometry}
        material={materials.PaletteMaterial001}
        position={[3.488, 3.724, -0.617]}
        rotation={[-Math.PI / 2, 0, -2.162]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketQuadroSwitch_Body.geometry}
        material={materials.PaletteMaterial001}
        position={[7.899, 0.865, 1.148]}
        rotation={[-Math.PI / 2, 0, 1.573]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard003.geometry}
        material={materials.PaletteMaterial001}
        position={[3.16, 0, 1.337]}
        rotation={[-Math.PI / 2, 0, 1.642]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard004.geometry}
        material={materials.PaletteMaterial001}
        position={[5.805, 0, 0.593]}
        rotation={[-Math.PI / 2, 0, 0.04]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard005.geometry}
        material={materials.PaletteMaterial001}
        position={[6.125, 0, 2.02]}
        rotation={[-Math.PI / 2, 0, -0.545]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard006.geometry}
        material={materials.PaletteMaterial001}
        position={[4.078, 0, 5.06]}
        rotation={[-Math.PI / 2, 0, 0.541]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard007.geometry}
        material={materials.PaletteMaterial001}
        position={[6.05, 0, 2.867]}
        rotation={[-Math.PI / 2, 0, -0.652]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BaseboardWindowBeadroom.geometry}
        material={materials.PaletteMaterial001}
        position={[6.964, 0, 6.466]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame07.geometry}
        material={materials.PaletteMaterial001}
        position={[5.159, 0.02, 6.596]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Glass01.geometry}
        material={materials.PaletteMaterial003}
        position={[5.159, 0.02, 6.86]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_BarLower01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.281, 0, 6.54]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_BarUpper01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.281, 2.44, 6.54]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame09.geometry}
        material={materials.PaletteMaterial001}
        position={[7.79, 0, 6.557]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame11.geometry}
        material={materials.PaletteMaterial001}
        position={[7.683, 0.03, 6.527]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame04.geometry}
        material={materials.PaletteMaterial001}
        position={[5.11, 0.03, 6.531]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame10.geometry}
        material={materials.PaletteMaterial001}
        position={[6.604, 0.03, 6.529]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame06.geometry}
        material={materials.PaletteMaterial001}
        position={[4.955, 0, 6.561]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_BarBehind01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.281, 2.44, 6.648]}
        rotation={[-Math.PI / 2, 0, Math.PI]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Glass02.geometry}
        material={materials.PaletteMaterial003}
        position={[7.209, 0.612, 6.61]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame05.geometry}
        material={materials.PaletteMaterial001}
        position={[7.562, 2.388, 6.594]}
        rotation={[-3.142, -1.56, -3.142]}
        scale={[-0.01, 0.01, 0.01]}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame05_1.geometry}
        material={materials.PaletteMaterial001}
        position={[7.562, 0.076, 6.594]}
        rotation={[3.142, -1.56, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame01.geometry}
        material={materials.PaletteMaterial001}
        position={[7.609, 0.029, 6.594]}
        rotation={[-Math.PI / 2, 0, -1.581]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame03.geometry}
        material={materials.PaletteMaterial001}
        position={[6.683, 2.435, 6.593]}
        rotation={[Math.PI / 2, 0, 1.581]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_RubberSeal01.geometry}
        material={materials.PaletteMaterial002}
        position={[7.284, 0.304, 6.698]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation06.geometry}
          material={materials.PaletteMaterial001}
          position={[6.992, 2.395, 6.555]}
          rotation={[Math.PI, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation07.geometry}
          material={materials.PaletteMaterial001}
          position={[6.832, 2.394, 6.547]}
          rotation={[Math.PI, 0.116, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation05.geometry}
          material={materials.PaletteMaterial001}
          position={[6.992, 2.395, 6.565]}
          rotation={[Math.PI, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation02.geometry}
          material={materials.PaletteMaterial001}
          position={[6.992, 2.395, 6.562]}
          rotation={[Math.PI, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation01.geometry}
          material={materials.PaletteMaterial001}
          position={[6.829, 2.394, 6.547]}
          rotation={[Math.PI, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation03.geometry}
          material={materials.PaletteMaterial001}
          position={[6.806, 2.395, 6.546]}
          rotation={[3.134, -0.008, 1.502]}
          scale={[0, 0, 0.001]}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation04.geometry}
          material={materials.PaletteMaterial001}
          position={[6.817, 2.395, 6.565]}
          rotation={[Math.PI, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle04.geometry}
        material={materials.PaletteMaterial001}
        position={[6.665, 0.99, 6.559]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle04_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.152, 1.562, -7.727]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle04_2.geometry}
        material={materials.PaletteMaterial001}
        position={[6.402, 1.562, -7.738]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle04_3.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.144, 1.562, -7.717]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle03.geometry}
        material={materials.PaletteMaterial001}
        position={[6.665, 1.022, 6.552]}
        rotation={[Math.PI, -0.003, Math.PI]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.152, 1.594, -7.72]}
        rotation={[0, 0.003, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle03_2.geometry}
        material={materials.PaletteMaterial001}
        position={[6.402, 1.594, -7.731]}
        rotation={[0, 0.003, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle03_3.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.144, 1.594, -7.71]}
        rotation={[0, 0.003, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle01.geometry}
        material={materials.PaletteMaterial001}
        position={[6.665, 1.022, 6.548]}
        rotation={[Math.PI, -0.003, Math.PI]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.152, 1.594, -7.716]}
        rotation={[0, 0.003, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[6.402, 1.594, -7.727]}
        rotation={[0, 0.003, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle01_3.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.144, 1.594, -7.706]}
        rotation={[0, 0.003, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle02.geometry}
        material={materials.PaletteMaterial001}
        position={[6.666, 1.055, 6.564]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle02_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.152, 1.627, -7.733]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle02_2.geometry}
        material={materials.PaletteMaterial001}
        position={[6.402, 1.627, -7.744]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle02_3.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.144, 1.627, -7.723]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle05.geometry}
        material={materials.PaletteMaterial001}
        position={[6.666, 1.057, 6.547]}
        rotation={[-1.384, -0.008, 3.137]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle05_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.152, 1.629, -7.715]}
        rotation={[-1.758, 0.008, -0.005]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle05_2.geometry}
        material={materials.PaletteMaterial001}
        position={[6.402, 1.629, -7.726]}
        rotation={[-1.758, 0.008, -0.005]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle05_3.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.144, 1.629, -7.705]}
        rotation={[-1.758, 0.008, -0.005]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle06.geometry}
        material={materials.PaletteMaterial001}
        position={[6.665, 0.99, 6.562]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle06_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.152, 1.562, -7.73]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle06_2.geometry}
        material={materials.PaletteMaterial001}
        position={[6.402, 1.562, -7.741]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Handle06_3.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.144, 1.562, -7.72]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Hook01.geometry}
        material={materials.PaletteMaterial002}
        position={[6.662, 0.833, 6.554]}
        rotation={[-Math.PI / 2, 0, -3.139]}
        scale={[0.007, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Hook01_1.geometry}
        material={materials.PaletteMaterial002}
        position={[2.155, 1.415, -7.723]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={[0.007, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Hook01_2.geometry}
        material={materials.PaletteMaterial002}
        position={[6.405, 1.415, -7.734]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={[0.007, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Hook01_3.geometry}
        material={materials.PaletteMaterial002}
        position={[-2.141, 1.415, -7.713]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={[0.007, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Filler02.geometry}
        material={materials.PaletteMaterial001}
        position={[7.875, 2.542, 6.556]}
        rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Filler03.geometry}
        material={materials.PaletteMaterial001}
        position={[7.875, 0, 6.557]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Filler01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.828, 0, 6.562]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall14.geometry}
        material={materials.PaletteMaterial001}
        position={[5.477, 0, 3.679]}
        rotation={[-Math.PI / 2, 0, -0.96]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard008.geometry}
        material={materials.PaletteMaterial001}
        position={[5.121, 0, 3.654]}
        rotation={[-Math.PI / 2, 0, 1.642]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard009.geometry}
        material={materials.PaletteMaterial001}
        position={[7.379, 0, 3.84]}
        rotation={[-Math.PI / 2, 0, -1.52]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard010.geometry}
        material={materials.PaletteMaterial001}
        position={[6.05, 0, 2.848]}
        rotation={[-Math.PI / 2, 0, -0.652]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard011.geometry}
        material={materials.PaletteMaterial001}
        position={[5.121, 0, 3.654]}
        rotation={[-Math.PI / 2, 0, 1.642]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard012.geometry}
        material={materials.PaletteMaterial001}
        position={[7.379, 0, 3.84]}
        rotation={[-Math.PI / 2, 0, -1.52]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame08.geometry}
        material={materials.PaletteMaterial001}
        position={[5.431, 0, 3.686]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame09.geometry}
        material={materials.PaletteMaterial001}
        position={[5.801, 0, 3.688]}
        rotation={[-Math.PI / 2, 0, -0.002]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame07.geometry}
        material={materials.PaletteMaterial001}
        position={[5.801, 2.045, 3.688]}
        rotation={[3.141, -1.569, -1.571]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_KeyLockPlate02.geometry}
        material={materials.PaletteMaterial001}
        position={[5.794, 0.99, 3.765]}
        rotation={[-Math.PI / 2, 0, -0.002]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_KeyLockPlate01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.814, 1.09, 3.505]}
        rotation={[Math.PI / 2, 0, 2.17]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Shaft05.geometry}
        material={materials.PaletteMaterial001}
        position={[4.472, 1.002, 4.131]}
        rotation={[Math.PI, -0.513, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Shaft05_1.geometry}
        material={materials.PaletteMaterial001}
        position={[4.487, 1.002, 4.159]}
        rotation={[0, 0.513, -Math.PI]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door03.geometry}
        material={materials.PaletteMaterial001}
        position={[4.754, 0.015, 3.99]}
        rotation={[-Math.PI / 2, 0, -2.628]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Latch03.geometry}
        material={materials.PaletteMaterial001}
        position={[4.432, 1.045, 4.172]}
        rotation={[-Math.PI / 2, 0, -2.635]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Latch02.geometry}
        material={materials.PaletteMaterial001}
        position={[5.856, 1.077, 2.196]}
        rotation={[Math.PI / 2, 0, -0.899]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Latch01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.391, 3.842, -0.457]}
        rotation={[Math.PI / 2, 0, -0.986]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Lock03.geometry}
        material={materials.PaletteMaterial001}
        position={[4.446, 0.971, 4.163]}
        rotation={[-Math.PI / 2, 0, -2.628]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Lock02.geometry}
        material={materials.PaletteMaterial001}
        position={[5.846, 1.151, 2.209]}
        rotation={[Math.PI / 2, 0, -0.892]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_HingeLower02.geometry}
        material={materials.PaletteMaterial001}
        position={[5.068, 0.243, 3.813]}
        rotation={[-Math.PI / 2, 0, 0.515]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_HingeLower02_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.068, 1.762, 3.813]}
        rotation={[-Math.PI / 2, 0, 0.515]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_HingeLower01.geometry}
        material={materials.PaletteMaterial001}
        position={[5.338, 0.321, 2.848]}
        rotation={[Math.PI / 2, 0, 2.243]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_HingeLower01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.338, 1.839, 2.848]}
        rotation={[Math.PI / 2, 0, 2.245]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_HingeLowerFrame02.geometry}
        material={materials.PaletteMaterial001}
        position={[5.068, 0.235, 3.763]}
        rotation={[-Math.PI / 2, 0, -0.009]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_HingeLowerFrame02_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.068, 1.754, 3.763]}
        rotation={[-Math.PI / 2, 0, -0.009]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_HingeLowerFrame01.geometry}
        material={materials.PaletteMaterial001}
        position={[5.287, 0.329, 2.82]}
        rotation={[Math.PI / 2, 0, 2.17]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_HingeLowerFrame01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.287, 1.849, 2.82]}
        rotation={[Math.PI / 2, 0, 2.17]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge06.geometry}
        material={materials.PaletteMaterial001}
        position={[5.063, 0.249, 3.786]}
        rotation={[Math.PI / 2, 0, -2.522]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge06_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.063, 1.768, 3.786]}
        rotation={[Math.PI / 2, 0, -2.522]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge04.geometry}
        material={materials.PaletteMaterial001}
        position={[5.31, 0.315, 2.829]}
        rotation={[-Math.PI / 2, 0, 1.582]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge04_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.31, 1.835, 2.829]}
        rotation={[-Math.PI / 2, 0, 1.582]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door04.geometry}
        material={materials.PaletteMaterial001}
        position={[1.888, 0.29, -4.414]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge09.geometry}
        material={materials.PaletteMaterial001}
        position={[2.149, 1.972, -4.414]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge09_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.149, 0.493, -4.414]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Latch04.geometry}
        material={materials.PaletteMaterial001}
        position={[1.628, 1.205, -4.396]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_KeyLockPlate04.geometry}
        material={materials.PaletteMaterial001}
        position={[1.632, 1.166, -4.396]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_KeyLockPlate03.geometry}
        material={materials.PaletteMaterial001}
        position={[1.625, 1.166, -4.376]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Keyhole04.geometry}
        material={materials.PaletteMaterial001}
        position={[1.678, 1.257, -4.405]}
        rotation={[Math.PI, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Shaft06.geometry}
        material={materials.PaletteMaterial001}
        position={[1.678, 1.253, -4.388]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame04.geometry}
        material={materials.PaletteMaterial001}
        position={[4.965, 0, 3.167]}
        rotation={[-Math.PI / 2, 0, 0.965]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame06.geometry}
        material={materials.PaletteMaterial001}
        position={[4.748, 0, 3.467]}
        rotation={[-Math.PI / 2, 0, 0.965]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame05.geometry}
        material={materials.PaletteMaterial001}
        position={[4.748, 2.045, 3.467]}
        rotation={[Math.PI, 0.606, Math.PI / 2]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.321, 4.849, -0.5]}
        rotation={[0, -0.592, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door02.geometry}
        material={materials.PaletteMaterial001}
        position={[5.577, 0.045, 2.547]}
        rotation={[-Math.PI / 2, 0, -2.243]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Shaft03.geometry}
        material={materials.PaletteMaterial001}
        position={[5.81, 1.032, 2.229]}
        rotation={[Math.PI, -0.899, 0]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Shaft03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.835, 1.032, 2.248]}
        rotation={[0, 0.899, Math.PI]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Shaft01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.367, 3.885, -0.408]}
        rotation={[0, 0.979, 0]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Bolt20.geometry}
        material={materials.PaletteMaterial001}
        position={[5.841, 1.026, 2.254]}
        rotation={[0, 0.899, -Math.PI]}
        scale={0.012}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_LockHandle03.geometry}
        material={materials.PaletteMaterial001}
        position={[5.844, 1.012, 2.255]}
        rotation={[-Math.PI / 2, 0, 2.517]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_LockHandle01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.377, 3.865, -0.402]}
        rotation={[-Math.PI / 2, 0, -0.544]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_LockHandle02.geometry}
        material={materials.PaletteMaterial001}
        position={[5.806, 1.026, 2.226]}
        rotation={[-2.175, 0.418, 2.61]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall15.geometry}
        material={materials.PaletteMaterial001}
        position={[3.924, 0, 4.472]}
        rotation={[-1.573, -0.017, -0.178]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Fins03.geometry}
        material={materials.PaletteMaterial001}
        position={[7.795, 0.414, -7.05]}
        rotation={[-3.141, -1.569, -3.141]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Fins03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[4.639, 0.414, 5.958]}
        rotation={[-Math.PI, 1.073, Math.PI]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Fins03_2.geometry}
        material={materials.PaletteMaterial001}
        position={[3.466, 3.222, 1.29]}
        rotation={[-Math.PI, -0.002, Math.PI]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapTop03.geometry}
        material={materials.PaletteMaterial001}
        position={[7.8, 0.675, -6.9]}
        rotation={[-Math.PI / 2, 0, -1.572]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapTop02.geometry}
        material={materials.PaletteMaterial001}
        position={[4.563, 0.675, 5.828]}
        rotation={[-Math.PI / 2, 0, 2.068]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapTop03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.316, 3.483, 1.295]}
        rotation={[-Math.PI / 2, 0, -3.14]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Valve03.geometry}
        material={materials.PaletteMaterial001}
        position={[7.788, 0.65, -7.356]}
        rotation={[-Math.PI, 0, Math.PI]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Valve03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.874, 0.41, -7.523]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Valve03_2.geometry}
        material={materials.PaletteMaterial001}
        position={[4.792, 0.649, 6.223]}
        rotation={[0, 0.499, 0]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Valve03_3.geometry}
        material={materials.PaletteMaterial001}
        position={[3.772, 3.458, 1.282]}
        rotation={[-3.142, 1.567, 3.142]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_PipePlasticCap03.geometry}
        material={materials.PaletteMaterial001}
        position={[7.763, 0, -7.28]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_PipePlasticCap03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.795, -0.002, -7.516]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_PipePlasticCap03_2.geometry}
        material={materials.PaletteMaterial001}
        position={[4.777, 0, 6.144]}
        rotation={[-Math.PI / 2, 0, -2.642]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_PipePlasticCap03_3.geometry}
        material={materials.PaletteMaterial001}
        position={[3.696, 2.809, 1.257]}
        rotation={[-Math.PI / 2, 0, -1.567]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Fins05.geometry}
        material={materials.PaletteMaterial001}
        position={[1.649, 0.346, -7.444]}
        rotation={[-Math.PI, 0.002, Math.PI]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapSide08.geometry}
        material={materials.PaletteMaterial001}
        position={[0.827, 0.414, -7.444]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapSide08_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.845, 0.414, -7.444]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapTop04.geometry}
        material={materials.PaletteMaterial001}
        position={[1.499, 0.435, -7.44]}
        rotation={[-Math.PI / 2, 0, 3.14]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCap07.geometry}
        material={materials.PaletteMaterial001}
        position={[1.379, 0.142, -7.548]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.01, 0.01, 0.006]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCap07_1.geometry}
        material={materials.PaletteMaterial001}
        position={[1.379, 0.142, -7.489]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.01, 0.01, 0.006]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCap07_2.geometry}
        material={materials.PaletteMaterial001}
        position={[1.379, 0.142, -7.401]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={[0.01, 0.01, 0.006]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Fins04.geometry}
        material={materials.PaletteMaterial001}
        position={[1.649, 0.346, -7.503]}
        rotation={[-Math.PI, 0.002, Math.PI]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapBottom07.geometry}
        material={materials.PaletteMaterial001}
        position={[1.379, 0.143, -7.548]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapBottom07_1.geometry}
        material={materials.PaletteMaterial001}
        position={[1.379, 0.143, -7.49]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_BodyCapBottom07_2.geometry}
        material={materials.PaletteMaterial001}
        position={[1.379, 0.143, -7.401]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Foot03.geometry}
        material={materials.PaletteMaterial001}
        position={[2.692, -0.003, -7.459]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Foot03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[0.972, -0.003, -7.459]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Foot02.geometry}
        material={materials.PaletteMaterial001}
        position={[2.692, 0, -7.461]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Foot02_1.geometry}
        material={materials.PaletteMaterial001}
        position={[0.972, 0, -7.461]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Foot01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.692, 0.05, -7.461]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Foot01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[0.972, 0.05, -7.461]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Pipes05.geometry}
        material={materials.PaletteMaterial001}
        position={[2.818, 0.409, -7.408]}
        rotation={[Math.PI, 0.002, -Math.PI]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Pipes05_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.818, 0.177, -7.408]}
        rotation={[Math.PI, 0.002, -Math.PI]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Pipes05_2.geometry}
        material={materials.PaletteMaterial001}
        position={[0.856, 0.41, -7.411]}
        rotation={[-Math.PI, 0.002, 0]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Pipes05_3.geometry}
        material={materials.PaletteMaterial001}
        position={[0.856, 0.178, -7.411]}
        rotation={[-Math.PI, 0.002, 0]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Pipes06.geometry}
        material={materials.PaletteMaterial001}
        position={[2.818, 0.409, -7.408]}
        rotation={[Math.PI, 0.002, -Math.PI]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_Pipes06_1.geometry}
        material={materials.PaletteMaterial001}
        position={[0.856, 0.41, -7.411]}
        rotation={[-Math.PI, 0.002, 0]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WiresShaft01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.256, -0.03, 1.353]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WiresShaft02.geometry}
        material={materials.PaletteMaterial001}
        position={[2.256, -0.03, 1.322]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.MaintenanceDoor.geometry}
        material={materials.PaletteMaterial001}
        position={[4.431, 0.19, 1.369]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Exhaust_Duct.geometry}
        material={materials.PaletteMaterial001}
        position={[2.14, 1.717, -3.303]}
        rotation={[-Math.PI / 2, 0, -0.596]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Exhaust_Hood.geometry}
        material={materials.PaletteMaterial001}
        position={[2.243, 1.458, -3.232]}
        rotation={[-Math.PI / 2, 0, -0.596]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall02.geometry}
        material={materials.PaletteMaterial001}
        position={[3.105, 4.94, 0.057]}
        rotation={[-Math.PI / 2, 0, 2.551]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.978, 2.51, 1.15]}
        rotation={[-Math.PI / 2, 0, -0.009]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall03.geometry}
        material={materials.PaletteMaterial001}
        position={[4.823, 2.51, 0.263]}
        rotation={[-Math.PI / 2, 0, -0.009]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall05.geometry}
        material={materials.PaletteMaterial001}
        position={[3.186, 0, 1.524]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GlassSeparator_Glass.geometry}
        material={materials.PaletteMaterial003}
        position={[3.65, 0.003, 2.687]}
        rotation={[-Math.PI / 2, 0, -0.604]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Drain_Frame.geometry}
        material={materials.PaletteMaterial001}
        position={[3.448, 0.002, 3.212]}
        rotation={[-Math.PI / 2, 0, -0.604]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Drain.geometry}
        material={materials.PaletteMaterial001}
        position={[3.448, 0.002, 3.212]}
        rotation={[-Math.PI / 2, 0, -0.604]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Frame04.geometry}
        material={materials.PaletteMaterial001}
        position={[4.985, 2.1, 2.159]}
        rotation={[-Math.PI / 2, 0, -0.595]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Frame03.geometry}
        material={materials.PaletteMaterial001}
        position={[5.244, 0.21, 2.334]}
        rotation={[-Math.PI / 2, 0, -0.595]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Frame03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[4.727, 0.21, 1.984]}
        rotation={[-Math.PI / 2, 0, -0.595]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Frame02.geometry}
        material={materials.PaletteMaterial001}
        position={[4.985, 0.175, 2.159]}
        rotation={[-Math.PI / 2, 0, -0.595]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Filler.geometry}
        material={materials.PaletteMaterial001}
        position={[4.985, 1.414, 2.159]}
        rotation={[-Math.PI / 2, 0, -0.595]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Door04.geometry}
        material={materials.PaletteMaterial001}
        position={[4.795, 0.174, 2.437]}
        rotation={[-Math.PI / 2, 0, 0.973]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Door04_1.geometry}
        material={materials.PaletteMaterial001}
        position={[4.795, 1.167, 2.437]}
        rotation={[-Math.PI / 2, 0, 0.973]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Hinge02.geometry}
        material={materials.PaletteMaterial001}
        position={[5.064, 2.09, 2.607]}
        rotation={[-Math.PI / 2, 0, -0.01]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Closet_Hinge02_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.064, 1.114, 2.607]}
        rotation={[-Math.PI / 2, 0, -0.01]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Flush_Panel.geometry}
        material={materials.PaletteMaterial001}
        position={[3.576, 0.833, 2.245]}
        rotation={[-1.571, -0.001, 0.967]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Flush_Logo02.geometry}
        material={materials.PaletteMaterial001}
        position={[3.576, 0.833, 2.245]}
        rotation={[-1.571, -0.001, 0.967]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Flush_ButtonPanel.geometry}
        material={materials.PaletteMaterial001}
        position={[3.576, 0.833, 2.245]}
        rotation={[-1.571, -0.001, 0.967]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Flush_Logo01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.576, 0.833, 2.245]}
        rotation={[-1.571, -0.001, 0.967]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Toilet.geometry}
        material={materials.PaletteMaterial001}
        position={[3.622, 0.416, 2.059]}
        rotation={[-Math.PI / 2, 0, 0.969]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Seat.geometry}
        material={materials.PaletteMaterial001}
        position={[3.622, 0.41, 2.059]}
        rotation={[-Math.PI / 2, 0, 0.969]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Ventilation_Outer.geometry}
          material={materials.PaletteMaterial001}
          position={[3.286, 2.167, 2.553]}
          rotation={[0, 0.967, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Ventilation_Inner.geometry}
          material={materials.PaletteMaterial001}
          position={[3.286, 2.167, 2.553]}
          rotation={[0, 0.967, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Sink.geometry}
        material={materials.PaletteMaterial001}
        position={[4.296, 0.864, 3.937]}
        rotation={[-Math.PI / 2, 0, 2.52]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Waste01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.208, 0.526, 3.871]}
        rotation={[-Math.PI / 2, 0, -0.621]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Waste02.geometry}
        material={materials.PaletteMaterial001}
        position={[4.208, 0.526, 3.871]}
        rotation={[-Math.PI / 2, 0, -0.621]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Cup.geometry}
        material={materials.PaletteMaterial001}
        position={[4.208, 0.463, 3.871]}
        rotation={[-Math.PI / 2, 0, -0.621]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_LockRing01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.247, 0.554, 3.898]}
        rotation={[0, 0.949, Math.PI / 2]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_LockRing01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[4.208, 0.596, 3.871]}
        rotation={[-Math.PI / 2, 0, -2.024]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Rose.geometry}
        material={materials.PaletteMaterial001}
        position={[4.226, 0.669, 3.847]}
        rotation={[-Math.PI / 2, 0, -0.621]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_PlasticTube.geometry}
        material={materials.PaletteMaterial001}
        position={[4.208, 0.596, 3.871]}
        rotation={[Math.PI / 2, 0, 0.621]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Tube01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.208, 0.663, 3.871]}
        rotation={[Math.PI / 2, 0, 0.621]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_TubeFitting.geometry}
        material={materials.PaletteMaterial001}
        position={[4.362, 0.554, 3.981]}
        rotation={[Math.PI, -0.949, Math.PI / 2]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Tube02.geometry}
        material={materials.PaletteMaterial001}
        position={[4.364, 0.554, 3.983]}
        rotation={[Math.PI, -0.949, Math.PI / 2]}
        scale={0.025}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Faucet_Bottom01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.308, 0.908, 3.945]}
        rotation={[-Math.PI / 2, 0, -2.187]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Faucet_Handles01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.308, 0.908, 3.945]}
        rotation={[-Math.PI / 2, 0, -2.187]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Faucet_Spout01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.308, 0.908, 3.945]}
        rotation={[-Math.PI / 2, 0, -2.187]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Faucet_Aerator01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.308, 0.909, 3.945]}
        rotation={[-Math.PI / 2, 0, -2.187]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Filler.geometry}
        material={materials.PaletteMaterial001}
        position={[4.354, 0.795, 3.975]}
        rotation={[-Math.PI / 2, 0, -0.01]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Overflow.geometry}
        material={materials.PaletteMaterial001}
        position={[4.283, 0.819, 3.927]}
        rotation={[3.109, -0.951, -1.597]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Basin_Strainer.geometry}
        material={materials.PaletteMaterial001}
        position={[4.208, 0.7, 3.87]}
        rotation={[-1.569, -0.001, -0.621]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Hatch.geometry}
        material={materials.PaletteMaterial001}
        position={[4.658, 2.264, 2.872]}
        rotation={[-Math.PI / 2, 0, -0.593]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame03.geometry}
        material={materials.PaletteMaterial001}
        position={[3.321, 2.805, -0.5]}
        rotation={[-Math.PI / 2, 0, 0.979]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Frame02.geometry}
        material={materials.PaletteMaterial001}
        position={[3.121, 2.805, -0.189]}
        rotation={[-Math.PI / 2, 0, 0.979]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.134, 4.872, -0.09]}
        rotation={[Math.PI / 2, 0, -0.979]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.949, 4.625, 0.251]}
        rotation={[-Math.PI, 0.511, 0]}
        scale={[-0.001, 0.001, 0.001]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[2.949, 4.376, 0.251]}
        rotation={[-Math.PI, 0.511, 0]}
        scale={[-0.001, 0.001, 0.001]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_Hinge01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[2.949, 3.111, 0.251]}
        rotation={[-Math.PI, 0.511, 0]}
        scale={[-0.001, 0.001, 0.001]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall04.geometry}
        material={materials.PaletteMaterial001}
        position={[5.973, 2.13, -0.026]}
        rotation={[-Math.PI / 2, 0, 2.549]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pillar01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.991, 0, -1.36]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.FloorSupportLights.geometry}
        material={materials.PaletteMaterial001}
        position={[4.954, 2.47, -0.212]}
        rotation={[-Math.PI / 2, 0, 2.549]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.FloorSupport01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.053, 2.524, 0.897]}
        rotation={[-Math.PI / 2, -0.003, Math.PI / 2]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.74, 2.81, 1.337]}
        rotation={[-Math.PI / 2, 0, 1.642]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard02.geometry}
        material={materials.PaletteMaterial001}
        position={[2.861, 2.81, 0.416]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 6]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard03.geometry}
        material={materials.PaletteMaterial001}
        position={[3.548, 2.81, -0.6]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 6]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.DoorCloser_Bolt.geometry}
        material={materials.PaletteMaterial001}
        position={[3.024, 4.821, 0.154]}
        rotation={[-Math.PI / 2, 0, -0.593]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.DoorCloser_Slide.geometry}
        material={materials.PaletteMaterial001}
        position={[3.1, 4.879, 0.043]}
        rotation={[-Math.PI / 2, 0, -0.611]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.DoorCloser_Rail.geometry}
        material={materials.PaletteMaterial001}
        position={[3.104, 4.885, 0.034]}
        rotation={[-Math.PI / 2, 0, -0.593]}
        scale={[0.005, 0.011, 0.004]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.DoorCloser_Body.geometry}
        material={materials.PaletteMaterial001}
        position={[3.058, 4.824, 0.101]}
        rotation={[-Math.PI / 2, 0, -0.593]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Intercom_Phone.geometry}
        material={materials.PaletteMaterial001}
        position={[3.52, 4.124, -0.578]}
        rotation={[-Math.PI / 2, 0, -0.593]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Intercom_Body.geometry}
        material={materials.PaletteMaterial001}
        position={[3.513, 4.124, -0.604]}
        rotation={[-Math.PI / 2, 0, -0.593]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Intercom_Button01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.535, 4.165, -0.624]}
        rotation={[0, 0.977, -Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Intercom_Button01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.535, 4.15, -0.624]}
        rotation={[0, 0.977, -Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Intercom_Cord.geometry}
        material={materials.PaletteMaterial001}
        position={[3.532, 4.035, -0.596]}
        rotation={[0, 0.977, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stairs_Step01.geometry}
        material={materials.PaletteMaterial001}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stairs_Frame01.geometry}
        material={materials.PaletteMaterial001}
        position={[5.291, 1.356, 0.506]}
        rotation={[-Math.PI / 2, 0.782, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stairs_Frame01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.291, 1.356, 1.297]}
        rotation={[-Math.PI / 2, 0.782, Math.PI / 2]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stairs_RailingPlate03.geometry}
        material={materials.PaletteMaterial001}
        position={[4.057, 2.617, 1.271]}
        rotation={[-Math.PI / 2, -0.003, 0]}
        scale={[-0.01, 0.01, 0.01]}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stairs_RailingPlate03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[4.057, 2.617, 0.531]}
        rotation={[-Math.PI / 2, -0.003, -3.142]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stairs_2ndFloorSupport.geometry}
        material={materials.PaletteMaterial001}
        position={[4.043, 2.524, 0.897]}
        rotation={[-Math.PI / 2, -0.003, Math.PI / 2]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stairs_SupportArm07.geometry}
        material={materials.PaletteMaterial001}
        position={[5.483, 2.371, 0.47]}
        rotation={[-Math.PI / 2, 0.782, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stairs_SupportArm07_1.geometry}
        material={materials.PaletteMaterial001}
        position={[5.483, 2.371, 1.332]}
        rotation={[-Math.PI / 2, 0.782, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Railing_Frame03.geometry}
        material={materials.PaletteMaterial001}
        position={[4.977, 2.747, 0.439]}
        rotation={[-Math.PI, 0, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Railing_Frame02.geometry}
        material={materials.PaletteMaterial001}
        position={[4.977, 2.81, 0.409]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Railing_Frame01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.972, 3.272, 0.409]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Railing_Rod01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.972, 3.528, 0.41]}
        rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Railing_Rod01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[4.972, 3.313, 0.41]}
        rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Railing_Rod01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[4.972, 3.098, 0.41]}
        rotation={[-Math.PI / 2, -Math.PI / 2, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Railing_Frame04.geometry}
        material={materials.PaletteMaterial001}
        position={[4.604, 3.735, 0.409]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Baseboard04.geometry}
        material={materials.PaletteMaterial001}
        position={[3.164, 2.81, -0.1]}
        rotation={[-Math.PI / 2, 0, -0.593]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Floor02.geometry}
        material={materials.PaletteMaterial001}
        position={[3.097, 2.808, 0.318]}
        rotation={[-Math.PI / 2, 0, 1.552]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame30.geometry}
        material={materials.PaletteMaterial001}
        position={[1.473, 0.02, -7.696]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame12.geometry}
        material={materials.PaletteMaterial001}
        position={[5.764, 0.02, -7.707]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame30_1.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.823, 0.02, -7.686]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame29.geometry}
        material={materials.PaletteMaterial001}
        position={[1.851, 0.95, -7.697]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame29_1.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.445, 0.95, -7.687]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame26.geometry}
        material={materials.PaletteMaterial001}
        position={[2.198, 0.02, -7.698]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame26_1.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.098, 0.02, -7.687]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_RubberSeal03.geometry}
        material={materials.PaletteMaterial002}
        position={[1.851, 1.005, -7.772]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_RubberSeal03_1.geometry}
        material={materials.PaletteMaterial002}
        position={[-2.445, 1.005, -7.761]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame24.geometry}
        material={materials.PaletteMaterial001}
        position={[1.851, 1.005, -7.763]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame24_1.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.445, 1.005, -7.753]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_GlassUpper03.geometry}
        material={materials.PaletteMaterial003}
        position={[1.805, 1.143, -7.771]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_GlassUpper03_1.geometry}
        material={materials.PaletteMaterial003}
        position={[-2.491, 1.143, -7.761]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame25.geometry}
        material={materials.PaletteMaterial001}
        position={[1.851, 1.01, -7.74]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame25_1.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.445, 1.01, -7.729]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_GlassLower02.geometry}
        material={materials.PaletteMaterial003}
        position={[1.901, 0.02, -7.757]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_GlassLower02_1.geometry}
        material={materials.PaletteMaterial003}
        position={[-2.395, 0.02, -7.747]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame22.geometry}
        material={materials.PaletteMaterial001}
        position={[1.901, 0.02, -7.757]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame22_1.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.395, 0.02, -7.747]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame23.geometry}
        material={materials.PaletteMaterial001}
        position={[1.851, 2.515, -7.738]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame23_1.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.445, 2.515, -7.727]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation18.geometry}
          material={materials.PaletteMaterial001}
          position={[1.862, 2.504, -7.723]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation18_1.geometry}
          material={materials.PaletteMaterial001}
          position={[6.122, 2.502, -7.734]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation18_2.geometry}
          material={materials.PaletteMaterial001}
          position={[-2.445, 2.504, -7.712]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation16.geometry}
          material={materials.PaletteMaterial001}
          position={[1.702, 2.505, -7.715]}
          rotation={[0, 0.116, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation16_1.geometry}
          material={materials.PaletteMaterial001}
          position={[5.962, 2.503, -7.726]}
          rotation={[0, 0.116, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation16_2.geometry}
          material={materials.PaletteMaterial001}
          position={[-2.604, 2.505, -7.705]}
          rotation={[0, 0.116, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation17.geometry}
          material={materials.PaletteMaterial001}
          position={[1.862, 2.504, -7.733]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation17_1.geometry}
          material={materials.PaletteMaterial001}
          position={[6.122, 2.502, -7.744]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation17_2.geometry}
          material={materials.PaletteMaterial001}
          position={[-2.445, 2.504, -7.722]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation22.geometry}
          material={materials.PaletteMaterial001}
          position={[1.862, 2.504, -7.73]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation22_1.geometry}
          material={materials.PaletteMaterial001}
          position={[6.122, 2.502, -7.741]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation22_2.geometry}
          material={materials.PaletteMaterial001}
          position={[-2.445, 2.504, -7.719]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation23.geometry}
          material={materials.PaletteMaterial001}
          position={[1.699, 2.504, -7.715]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation23_1.geometry}
          material={materials.PaletteMaterial001}
          position={[5.959, 2.502, -7.726]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation23_2.geometry}
          material={materials.PaletteMaterial001}
          position={[-2.607, 2.504, -7.705]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation21.geometry}
          material={materials.PaletteMaterial001}
          position={[1.675, 2.504, -7.714]}
          rotation={[-0.008, -0.008, 1.502]}
          scale={0}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation21_1.geometry}
          material={materials.PaletteMaterial001}
          position={[5.936, 2.502, -7.725]}
          rotation={[-0.008, -0.008, 1.502]}
          scale={0}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation21_2.geometry}
          material={materials.PaletteMaterial001}
          position={[-2.631, 2.504, -7.704]}
          rotation={[-0.008, -0.008, 1.502]}
          scale={[0, 0, 0.001]}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation20.geometry}
          material={materials.PaletteMaterial001}
          position={[1.687, 2.504, -7.733]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation20_1.geometry}
          material={materials.PaletteMaterial001}
          position={[5.947, 2.502, -7.744]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Window_Ventilation20_2.geometry}
          material={materials.PaletteMaterial001}
          position={[-2.619, 2.504, -7.723]}
          rotation={[0, 0.003, -Math.PI / 2]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame14.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.32, 0.02, -7.748]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame14_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.97, 0.02, -7.758]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Glass03.geometry}
        material={materials.PaletteMaterial003}
        position={[-0.32, 0.02, -7.748]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Glass03_1.geometry}
        material={materials.PaletteMaterial003}
        position={[3.97, 0.02, -7.758]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame15.geometry}
        material={materials.PaletteMaterial001}
        position={[8.33, 0.02, -7.748]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Glass04.geometry}
        material={materials.PaletteMaterial003}
        position={[8.33, 0.02, -7.748]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame13.geometry}
        material={materials.PaletteMaterial001}
        position={[6.121, 0.95, -7.708]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame19.geometry}
        material={materials.PaletteMaterial001}
        position={[6.449, 0.02, -7.708]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_RubberSeal02.geometry}
        material={materials.PaletteMaterial002}
        position={[6.121, 1.005, -7.782]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame20.geometry}
        material={materials.PaletteMaterial001}
        position={[6.121, 1.005, -7.774]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_GlassUpper02.geometry}
        material={materials.PaletteMaterial003}
        position={[6.075, 1.143, -7.782]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame18.geometry}
        material={materials.PaletteMaterial001}
        position={[6.121, 1.01, -7.75]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_GlassLower03.geometry}
        material={materials.PaletteMaterial003}
        position={[6.171, 0.02, -7.768]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame16.geometry}
        material={materials.PaletteMaterial001}
        position={[6.171, 0.02, -7.768]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Frame17.geometry}
        material={materials.PaletteMaterial001}
        position={[6.121, 2.515, -7.748]}
        rotation={[-Math.PI / 2, 0, 0.003]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_BarSide01.geometry}
        material={materials.PaletteMaterial001}
        position={[7.816, 0, -7.623]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Window_Baseboard01.geometry}
        material={materials.PaletteMaterial001}
        position={[-2.125, 0, -7.634]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Window_FireproofCeiling01-01'].geometry}
        material={materials.PaletteMaterial001}
        position={[2.561, 2.435, -7.497]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Window_FireproofCeiling01-02'].geometry}
        material={materials.PaletteMaterial001}
        position={[2.561, 2.435, -7.497]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Window_BarLower02-01'].geometry}
        material={materials.PaletteMaterial001}
        position={[2.558, 0, -7.692]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Window_BarLower02-02'].geometry}
        material={materials.PaletteMaterial001}
        position={[2.558, 0, -7.692]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Window_BarLower02-03'].geometry}
        material={materials.PaletteMaterial001}
        position={[2.558, 0, -7.692]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Window_BarLower02-04'].geometry}
        material={materials.PaletteMaterial001}
        position={[2.558, 0, -7.692]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Window_BarLower02-05'].geometry}
        material={materials.PaletteMaterial001}
        position={[2.558, 0, -7.692]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes['Window_BarLower02-06'].geometry}
        material={materials.PaletteMaterial001}
        position={[2.558, 0, -7.692]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketWall_Body01.geometry}
        material={materials.PaletteMaterial001}
        position={[2.428, 0.986, -3.983]}
        rotation={[-Math.PI / 2, 0, -2.17]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketWall_Body01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[1.638, 0.986, -2.817]}
        rotation={[-Math.PI / 2, 0, -2.17]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketWall_Body01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[1.099, 0.986, -2.021]}
        rotation={[-Math.PI / 2, 0, -2.17]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sink_Strainer.geometry}
        material={materials.PaletteMaterial001}
        position={[1.578, 0.744, -2.212]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sink_Cap.geometry}
        material={materials.PaletteMaterial001}
        position={[1.474, 0.866, -2.282]}
        rotation={[-1.718, 0.099, 2.553]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sink_StrainerBasket.geometry}
        material={materials.PaletteMaterial001}
        position={[1.289, 0.784, -2.643]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={0}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sink_Holder.geometry}
        material={materials.PaletteMaterial001}
        position={[1.465, 0.909, -2.289]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sink.geometry}
        material={materials.PaletteMaterial001}
        position={[1.601, 0.739, -2.197]}
        rotation={[-Math.PI / 2, 0, 2.545]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Radiator_ValveIndicator02.geometry}
        material={materials.PaletteMaterial001}
        position={[4.791, 0.666, 6.231]}
        rotation={[-1.655, 0.153, -2.636]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_LampOuterShell01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.989, 2.261, 2.325]}
        rotation={[-1.571, 0, -0.01]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_LampOuterShell01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.986, 2.261, 3.904]}
        rotation={[-1.571, 0, -0.01]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_LampOuterShell01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[3.471, 2.261, 3.049]}
        rotation={[-1.571, 0, -0.01]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_LampOuterShell01_3.geometry}
        material={materials.PaletteMaterial001}
        position={[4.23, 2.261, 3.562]}
        rotation={[-1.571, 0, -0.01]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_LampReflector01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.989, 2.287, 2.325]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_LampReflector01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.986, 2.287, 3.904]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_LampReflector01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[3.47, 2.287, 3.049]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_LampReflector01_3.geometry}
        material={materials.PaletteMaterial001}
        position={[4.23, 2.287, 3.562]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.989, 2.287, 2.325]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.986, 2.287, 3.904]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[3.47, 2.287, 3.049]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb01_3.geometry}
        material={materials.PaletteMaterial001}
        position={[4.23, 2.287, 3.562]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Glass01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.989, 2.274, 2.325]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Glass01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.986, 2.274, 3.904]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Glass01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[3.47, 2.274, 3.049]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Glass01_3.geometry}
        material={materials.PaletteMaterial001}
        position={[4.23, 2.274, 3.562]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <MeshTransmissionMaterial
          distortionScale={0}
          temporalDistortion={0}
          {...opts?.materials?.windowGlass}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_InnerRing01.geometry}
        material={materials.PaletteMaterial001}
        position={[3.989, 2.275, 2.325]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_InnerRing01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.986, 2.275, 3.904]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_InnerRing01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[3.47, 2.275, 3.049]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_InnerRing01_3.geometry}
        material={materials.PaletteMaterial001}
        position={[4.23, 2.275, 3.562]}
        rotation={[-1.688, 0.34, 0.041]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb02.geometry}
        material={materials.PaletteMaterial001}
        position={[3.988, 2.284, 2.325]}
        rotation={[-1.674, 0.34, -0.016]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb02_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.985, 2.284, 3.904]}
        rotation={[-1.674, 0.34, -0.016]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb02_2.geometry}
        material={materials.PaletteMaterial001}
        position={[3.47, 2.284, 3.049]}
        rotation={[-1.674, 0.34, -0.016]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb02_3.geometry}
        material={materials.PaletteMaterial001}
        position={[4.23, 2.284, 3.562]}
        rotation={[-1.674, 0.34, -0.016]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb03.geometry}
        material={materials.PaletteMaterial001}
        position={[3.988, 2.284, 2.325]}
        rotation={[-1.674, 0.34, -0.016]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb03_1.geometry}
        material={materials.PaletteMaterial001}
        position={[3.985, 2.284, 3.904]}
        rotation={[-1.674, 0.34, -0.016]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb03_2.geometry}
        material={materials.PaletteMaterial001}
        position={[3.47, 2.284, 3.049]}
        rotation={[-1.674, 0.34, -0.016]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_Bulb03_3.geometry}
        material={materials.PaletteMaterial001}
        position={[4.23, 2.284, 3.562]}
        rotation={[-1.674, 0.34, -0.016]}
        scale={0.006}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_CoronaLight010.geometry}
        material={materials.PaletteMaterial002}
        position={[3.989, 2.29, 2.325]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.002}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_CoronaLight010_1.geometry}
        material={materials.PaletteMaterial002}
        position={[3.986, 2.29, 3.904]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.002}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_CoronaLight010_2.geometry}
        material={materials.PaletteMaterial002}
        position={[3.471, 2.29, 3.049]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.002}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Light_CoronaLight010_3.geometry}
        material={materials.PaletteMaterial002}
        position={[4.23, 2.29, 3.563]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.002}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SocketDoubleSwitch_Socket01.geometry}
        material={materials.PaletteMaterial001}
        position={[4.668, 0.998, 3.579]}
        rotation={[-Math.PI / 2, 0, 0.945]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Balcony_Concrete.geometry}
        material={materials.PaletteMaterial001}
        position={[7.83, 0, 7.299]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Balcony_RailingBottomHolder01.geometry}
        material={materials.PaletteMaterial001}
        position={[7.615, 0.014, 8.101]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Balcony_RailingBottomHolder01_1.geometry}
        material={materials.PaletteMaterial001}
        position={[6.709, 0.014, 8.101]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Balcony_RailingBottomHolder01_2.geometry}
        material={materials.PaletteMaterial001}
        position={[5.779, 0.014, 8.101]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Balcony_RailingMain.geometry}
        material={materials.PaletteMaterial001}
        position={[7.627, 0.039, 8.101]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BaseFloor.geometry}
        material={materials.PaletteMaterial001}
        position={[3.401, 0.193, 0.972]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pillar02.geometry}
        material={materials.PaletteMaterial001}
        position={[3.708, 0, -6.009]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.01}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet001.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet002.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet003.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet004.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet005.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet006.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet007.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet008.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet009.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet010.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet011.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet012.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet013.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet014.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet015.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet016.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Kitchen_Faucet017.geometry}
        material={materials.PaletteMaterial001}
        position={[1.441, 0.912, -2.304]}
        rotation={[-Math.PI / 2, 0, 0.971]}
        scale={0.001}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </mesh>

      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Ventilation1.geometry}
          material={materials.PaletteMaterial001}
          position={[1.358, -0.288, -3.75]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Ventilation3.geometry}
          material={materials.PaletteMaterial001}
          position={[2.729, 0, 2.221]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Ventilation2.geometry}
          material={materials.PaletteMaterial001}
          position={[1.358, 2.178, -3.75]}
          rotation={[-0.315, 1.009, -1.493]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.ventilation?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Ventilation4.geometry}
          material={materials.PaletteMaterial001}
          position={[2.729, 2.073, 2.221]}
          rotation={[-0.315, 1.009, -1.493]}
          scale={0.01}
        >
          {opts?.ventilation?.enabled && (
            <Edges {...opts?.ventilation?.props}>
              <meshStandardMaterial {...opts?.ventilation?.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.ventilation} />
        </mesh>
      )}
      {opts?.plumbing?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PlumbingHot1.geometry}
          material={materials.PaletteMaterial001}
          position={[1.744, -0.288, -3.821]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          {opts?.plumbing?.enabled && (
            <Edges {...opts?.plumbing.hot.props}>
              <meshBasicMaterial {...opts?.plumbing.hot.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.plumbing} />
        </mesh>
      )}
      {opts?.plumbing?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PlumbingCold1.geometry}
          material={materials.PaletteMaterial001}
          position={[1.921, -0.288, -3.821]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          {opts?.plumbing?.enabled && (
            <Edges {...opts?.plumbing.cold.props}>
              <meshBasicMaterial {...opts?.plumbing.cold.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.plumbing} />
        </mesh>
      )}
      {opts?.plumbing?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PlumbingCloak.geometry}
          material={materials.PaletteMaterial001}
          position={[1.853, -0.288, -3.986]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          {opts?.plumbing?.enabled && (
            <Edges {...opts?.plumbing.cold.props}>
              <meshBasicMaterial {...opts?.plumbing.cold.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.plumbing} />
        </mesh>
      )}
      {opts?.plumbing?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Obj_PlumbingCloak.geometry}
          material={materials.PaletteMaterial001}
          position={[1.835, -0.104, -3.986]}
          rotation={[-0.087, -0.087, 0]}
          scale={0.01}
        >
          {opts?.plumbing?.enabled && (
            <Edges {...opts?.plumbing.cold.props}>
              <meshBasicMaterial {...opts?.plumbing.cold.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.plumbing} />
        </mesh>
      )}
      {opts?.plumbing?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PlumbingHot2.geometry}
          material={materials.PaletteMaterial001}
          position={[1.744, 2.634, -3.821]}
          rotation={[0, Math.PI / 9, 0]}
          scale={0.01}
        >
          {opts?.plumbing?.enabled && (
            <Edges {...opts?.plumbing.hot.props}>
              <meshBasicMaterial {...opts?.plumbing.hot.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.plumbing} />
        </mesh>
      )}
      {opts?.plumbing?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PlumbingCold2.geometry}
          material={materials.PaletteMaterial001}
          position={[1.921, 2.562, -3.821]}
          rotation={[0, Math.PI / 9, 0]}
          scale={0.01}
        >
          {opts?.plumbing?.enabled && (
            <Edges {...opts?.plumbing.cold.props}>
              <meshBasicMaterial {...opts?.plumbing.cold.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.plumbing} />
        </mesh>
      )}
      {opts?.plumbing?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PlumbingRadiatorMinus1.geometry}
          material={materials.PaletteMaterial001}
          position={[6.114, 0.075, -7.396]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          {opts?.plumbing?.enabled && (
            <Edges {...opts?.plumbing.cold.props}>
              <meshBasicMaterial {...opts?.plumbing.cold.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.plumbing} />
        </mesh>
      )}
      {opts?.plumbing?.enabled && (
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PlumbingRadiatorPlus1.geometry}
          material={materials.PaletteMaterial001}
          position={[6.114, 0.075, -7.423]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.01}
        >
          {opts?.plumbing?.enabled && (
            <Edges {...opts?.plumbing.cold.props}>
              <meshBasicMaterial {...opts?.plumbing.cold.material} />
            </Edges>
          )}

          <meshStandardMaterial {...opts?.materials?.plumbing} />
        </mesh>
      )}
      <instancedMesh
        args={[nodes.Socket_Body10.geometry, materials.PaletteMaterial001, 13]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Socket_Body10.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Socket_Cap10.geometry, materials.PaletteMaterial001, 22]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Socket_Cap10.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Socket_Screw10.geometry, materials.PaletteMaterial001, 22]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Socket_Screw10.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.SocketLight05.geometry, materials.PaletteMaterial001, 7]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.SocketLight05.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Window_FrameCap10.geometry,
          materials.PaletteMaterial001,
          10,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Window_FrameCap10.instanceMatrix}
      >
        {opts?.edges?.enabled && (
          <Edges {...opts?.edges?.props}>
            <meshStandardMaterial {...opts?.edges?.material} />
          </Edges>
        )}

        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Door_Bolt49.geometry, materials.PaletteMaterial001, 28]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Door_Bolt49.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Door_Bolt29.geometry, materials.PaletteMaterial001, 20]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Door_Bolt29.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Door_Shaft04.geometry, materials.PaletteMaterial001, 5]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Door_Shaft04.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Door_Handle03.geometry, materials.PaletteMaterial001, 5]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Door_Handle03.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Door_PlasticCap14.geometry,
          materials.PaletteMaterial001,
          16,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Door_PlasticCap14.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Radiator_BodyCapSide06.geometry,
          materials.PaletteMaterial001,
          6,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Radiator_BodyCapSide06.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Radiator_Pipes30.geometry,
          materials.PaletteMaterial001,
          12,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Radiator_Pipes30.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Radiator_PipeFitting11.geometry,
          materials.PaletteMaterial001,
          16,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Radiator_PipeFitting11.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Radiator_Hooks08.geometry,
          materials.PaletteMaterial001,
          12,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Radiator_Hooks08.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Radiator_BodyCapBottom05.geometry,
          materials.PaletteMaterial001,
          6,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Radiator_BodyCapBottom05.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Radiator_BodyCap05.geometry,
          materials.PaletteMaterial001,
          6,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Radiator_BodyCap05.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Radiator_PipeFitting14.geometry,
          materials.PaletteMaterial001,
          8,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Radiator_PipeFitting14.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Radiator_Pipes28.geometry,
          materials.PaletteMaterial001,
          8,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Radiator_Pipes28.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Stairs_RailingPlate01.geometry,
          materials.PaletteMaterial001,
          16,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Stairs_RailingPlate01.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Stairs_Nut05.geometry, materials.PaletteMaterial001, 16]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Stairs_Nut05.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Stairs_Fastener18.geometry,
          materials.PaletteMaterial001,
          20,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Stairs_Fastener18.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Stairs_Cap05.geometry, materials.PaletteMaterial001, 16]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Stairs_Cap05.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.mesh_195.geometry, nodes.mesh_195.material, 24]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.mesh_195.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Stairs_Rod01.geometry, materials.PaletteMaterial001, 6]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Stairs_Rod01.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Stairs_SupportArm06.geometry,
          materials.PaletteMaterial001,
          6,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Stairs_SupportArm06.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[nodes.Stairs_Step12.geometry, materials.PaletteMaterial001, 12]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Stairs_Step12.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Balcony_RailingBar01.geometry,
          materials.PaletteMaterial001,
          14,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Balcony_RailingBar01.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>

      <instancedMesh
        args={[
          nodes.Balcony_RailingBolt01.geometry,
          materials.PaletteMaterial001,
          6,
        ]}
        castShadow
        receiveShadow
        instanceMatrix={nodes.Balcony_RailingBolt01.instanceMatrix}
      >
        <meshStandardMaterial {...opts?.materials?.walls} />
      </instancedMesh>
    </group>
  );
}

useGLTF.preload('/assets/test-transformed.glb');
