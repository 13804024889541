import {
  CameraControls,
  Environment,
  useProgress,
  Html,
} from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { button, buttonGroup, folder, useControls } from 'leva';
import { useRef, Suspense, Ref, useEffect } from 'react';
import * as THREE from 'three';
import { Model } from './models/Test';
import { EdgesProps } from 'types/edges';
import { useEdgesControls } from './hooks/client-hooks/use-edges-controls';
import { useGlassMaterial } from './materials/use-glass-material';
import { useWallsMaterial } from './materials/use-walls-material';
import { useUIControls } from './hooks/client-hooks/use-ui-controls';
import { usePlumbingControls } from './hooks/client-hooks/use-plumbing-controls';
import { useVentilationControls } from './hooks/client-hooks/use-ventilation-controls';
import { PlumbingProps } from 'types/plumbing';
import { useAnnotations } from './hooks/data-hooks/use-annotations';
import Annotation, { AnnotationProps } from 'components/annotation';
import { useAnnotations as useAnnotationControls } from './hooks/client-hooks/use-annotations';
import { useModal } from './hooks/client-hooks/use-modal';
import Modal from 'components/modal';

const { DEG2RAD } = THREE.MathUtils;

const defaultCameraState: string = JSON.stringify({
  enabled: true,
  minDistance: 0,
  maxDistance: 1.7976931348623157e308,
  minZoom: 0.01,
  maxZoom: 1.7976931348623157e308,
  minPolarAngle: 0,
  maxPolarAngle: 3.141592653589793,
  minAzimuthAngle: -1.7976931348623157e308,
  maxAzimuthAngle: 1.7976931348623157e308,
  smoothTime: 0.25,
  draggingSmoothTime: 0.125,
  dollySpeed: 1,
  truckSpeed: 2,
  dollyToCursor: true,
  verticalDragToForward: false,
  target: [0.47242005834760675, -1.0975925322800142, -0.8740968838491912],
  position: [10.133155645041501, 5.969689142110232, -11.520337262581892],
  zoom: 1,
  focalOffset: [0, 0, 0],
  target0: [0.47242005834760675, -1.0975925322800142, -0.8740968838491912],
  position0: [10.133155645041501, 5.969689142110232, -11.520337262581892],
  zoom0: 1,
  focalOffset0: [0, 0, 0],
});

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <div style={{ color: 'white' }}>{Math.floor(progress)} % loaded</div>
    </Html>
  );
}

export default function App() {
  return (
    <Canvas shadows camera={{ fov: 60 }}>
      <Suspense fallback={<Loader />}>
        <Scene />
      </Suspense>
    </Canvas>
  );
}

function Scene() {
  const cameraControlsRef = useRef<CameraControls>();

  const { camera } = useThree();

  const {
    Wireframe: edgesEnabled,
    Plumbing: plumbingEnabled,
    Ventilation: ventilationEnabled,
  } = useUIControls();

  const { modalOpenState, closeModal, openModal } = useModal();

  const {
    annotationState,
    setCurrentAnnotation,
    setAnnotationMode,
    annotations,
  } = useAnnotations();

  const handleSetCurrentAnnotation = (annotation: AnnotationProps | null) => {
    setCurrentAnnotation(annotation);

    if (!annotation?.snapshot || !cameraControlsRef.current) return;

    handleAnnotationZoom(cameraControlsRef.current, annotation.snapshot).then(
      openModal,
    );
  };

  const { handleAnnotationZoom } = useAnnotationControls({
    annotations: annotations,
    camera: cameraControlsRef.current,
    handleSetCurrentAnnotation,
  });

  const [{ ...glassMaterialOpts }] = useGlassMaterial();
  const [{ ...wallsMaterialOpts }] = useWallsMaterial();
  const [{ ...edgesOpts2 }] = useEdgesControls();

  const plumbingMaterial = usePlumbingControls();
  const [{ ...ventilationOpts2 }] = useVentilationControls();

  const edgesOpts: EdgesProps = {
    props: { renderOrder: 1000 },
    material: edgesOpts2,
    enabled: edgesEnabled,
  };

  const plumbingHotOpts: PlumbingProps['hot'] = {
    props: { renderOrder: 1000 },
    material: { ...plumbingMaterial, color: plumbingMaterial.color_hot },
    enabled: plumbingEnabled,
  };

  const plumbingColdOpts: PlumbingProps['cold'] = {
    props: { renderOrder: 1000 },
    material: { ...plumbingMaterial, color: plumbingMaterial.color_cold },
    enabled: plumbingEnabled,
  };

  const ventilationOpts: EdgesProps = {
    props: { renderOrder: 1000 },
    material: ventilationOpts2,
    enabled: ventilationEnabled,
  };

  useEffect(() => {
    if (cameraControlsRef.current)
      cameraControlsRef.current.fromJSON(defaultCameraState);
  }, []);

  const {
    minDistance,
    enabled,
    verticalDragToForward,
    dollyToCursor,
    infinityDolly,
  } = useControls(
    'Debug Camera',
    {
      thetaGrp: buttonGroup({
        label: 'rotate theta',
        opts: {
          '+45º': () =>
            cameraControlsRef.current?.rotate(45 * DEG2RAD, 0, true),
          '-90º': () =>
            cameraControlsRef.current?.rotate(-90 * DEG2RAD, 0, true),
          '+360º': () =>
            cameraControlsRef.current?.rotate(360 * DEG2RAD, 0, true),
        },
      }),
      phiGrp: buttonGroup({
        label: 'rotate phi',
        opts: {
          '+20º': () =>
            cameraControlsRef.current?.rotate(0, 20 * DEG2RAD, true),
          '-40º': () =>
            cameraControlsRef.current?.rotate(0, -40 * DEG2RAD, true),
        },
      }),
      truckGrp: buttonGroup({
        label: 'truck',
        opts: {
          '(1,0)': () => cameraControlsRef.current?.truck(1, 0, true),
          '(0,1)': () => cameraControlsRef.current?.truck(0, 1, true),
          '(-1,-1)': () => cameraControlsRef.current?.truck(-1, -1, true),
        },
      }),
      dollyGrp: buttonGroup({
        label: 'dolly',
        opts: {
          1: () => cameraControlsRef.current?.dolly(1, true),
          '-1': () => cameraControlsRef.current?.dolly(-1, true),
        },
      }),
      zoomGrp: buttonGroup({
        label: 'zoom',
        opts: {
          '/2': () => cameraControlsRef.current?.zoom(camera.zoom / 2, true),
          '/-2': () => cameraControlsRef.current?.zoom(-camera.zoom / 2, true),
        },
      }),
      minDistance: { value: 0 },
      moveTo: folder(
        {
          vec1: { value: [3, 5, 2], label: 'vec' },
          'moveTo(…vec)': button(get =>
            cameraControlsRef.current?.moveTo(
              ...(get('moveTo.vec1') as [number, number, number]),
              true,
            ),
          ),
        },
        { collapsed: true },
      ),
      setPosition: folder(
        {
          vec2: { value: [-5, 2, 1], label: 'vec' },
          'setPosition(…vec)': button(get =>
            cameraControlsRef.current?.setPosition(
              ...(get('setPosition.vec2') as [number, number, number]),
              true,
            ),
          ),
        },
        { collapsed: true },
      ),
      saveState: button(() => {
        cameraControlsRef.current?.saveState();
        console.log(cameraControlsRef.current?.toJSON());
      }),
      reset: button(() => cameraControlsRef.current?.reset(true)),
      enabled: { value: true, label: 'controls on' },
      verticalDragToForward: {
        value: false,
        label: 'vert. drag to move forward',
      },
      dollyToCursor: { value: true, label: 'dolly to cursor' },
      infinityDolly: { value: false, label: 'infinity dolly' },
    },
    {
      collapsed: true,
    },
  );

  /*   const scene = useThree(state => state.scene);

  const currentTransformableObject = useMemo(
    () =>
      (annotationState.current &&
        scene.getObjectByName(annotationState.current.name)) ||
      undefined,
    [annotationState, scene],
  );

    const handleAnnotationTransform = (
    object: THREE.Object3D<THREE.Object3DEventMap>,
  ) => updateAnnotationPosition(object.position); */

  return (
    <group>
      <Environment files="assets/env.hdr" />
      <Model
        opts={{
          edges: edgesOpts,
          plumbing: {
            enabled: plumbingEnabled,
            hot: plumbingHotOpts,
            cold: plumbingColdOpts,
          },
          ventilation: ventilationOpts,
          materials: {
            windowGlass: glassMaterialOpts,
            walls: wallsMaterialOpts,
          },
        }}
      />
      {annotations &&
        annotations.map(annotation => (
          <Annotation
            key={annotation.name}
            name={annotation.name}
            text={annotation.text}
            position={annotation.position}
            onClick={e => (
              e.stopPropagation(), handleSetCurrentAnnotation(annotation)
            )}
            onPointerMissed={e =>
              e.type === 'click' && handleSetCurrentAnnotation(null)
            }
            onContextMenu={e =>
              annotationState.current?.name === annotation.name &&
              (e.stopPropagation(), setAnnotationMode())
            }
          />
        ))}
      {/*       {annotationState.current && (
        <TransformControls
          object={currentTransformableObject}
          mode={modes[annotationState.mode]}
          onObjectChange={() =>
            currentTransformableObject &&
            handleAnnotationTransform(currentTransformableObject)
          }
        />
      )} */}

      <CameraControls
        ref={cameraControlsRef as Ref<CameraControls>}
        makeDefault
        minDistance={minDistance}
        enabled={enabled}
        verticalDragToForward={verticalDragToForward}
        dollyToCursor={dollyToCursor}
        infinityDolly={infinityDolly}
      />
      <Html>
        {annotationState.current && (
          <Modal
            open={modalOpenState}
            handleClose={closeModal}
            title={annotationState.current.text}
            image={annotationState.current.image}
            description={annotationState.current?.description}
          />
        )}
      </Html>
    </group>
  );
}
