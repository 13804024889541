import { useControls, folder } from 'leva';

export function useGlassMaterial() {
  return useControls(
    'Window Glass',
    () => ({
      Material: folder({
        transmissionSampler: true,
        backside: false,
        samples: { value: 10, min: 1, max: 32, step: 1 },
        resolution: { value: 1024, min: 256, max: 2048, step: 256 },
        transmission: { value: 1, min: 0, max: 1 },
        roughness: { value: 0.0, min: 0, max: 1, step: 0.01 },
        thickness: { value: 3.5, min: 0, max: 10, step: 0.01 },
        ior: { value: 1.5, min: 1, max: 5, step: 0.01 },
        chromaticAberration: { value: 0, min: 0, max: 1 },
        anisotropy: { value: 0, min: 0, max: 1, step: 0.01 },
        distortion: { value: 0, min: 0, max: 1, step: 0.01 },
        distortionScale: { value: 0, min: 0.01, max: 1, step: 0.01 },
        temporalDistortion: { value: 0, min: 0, max: 1, step: 0.01 },
        clearcoat: { value: 1, min: 0, max: 1 },
        attenuationDistance: { value: 0.5, min: 0, max: 10, step: 0.01 },
        attenuationColor: '#ecfff3',
        color: '#c7c7c7',
      }),
    }),
    {
      collapsed: true,
    },
  );
}
